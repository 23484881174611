import React, { useState } from "react";
import { ButtonLink } from "../atoms";
// import * as WufooForm from "react-wufoo-embed";
// import IframeResizer from "iframe-resizer-react";
import WufooForm from "../global/WufooForm";
import { UniversalLink } from "../global";

const ContactMethodList = () => {
  return (
    <div className="bg-gray-50">
      <div className="max-w-screen-xl mx-auto sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="mt-6 border-t-2 border-gray-200 pt-6">
            <dl className={"space-y-4"}>
              <Item title={<PhotoTitle />} content={<PhotoContent />} />
              <Item title={<StoryTitle />} content={<StoryContent />} />
              <Item
                title={<SponsorshipTitle />}
                content={<SponsorshipContent />}
              />
              <Item
                title={<GeneralInquiriesTitle />}
                content={<GeneralInquiriesContent />}
              />
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const Item = ({ title, content }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div>
      <dt className="text-xl md:text-2xl leading-7">
        {/*Expand/collapse question button */}
        <button
          onClick={() => setOpen(!isOpen)}
          className={`${
            isOpen ? "" : "hover:bg-gray-100"
          } py-4 text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900`}
        >
          <span className="font-medium text-gray-900">{title}</span>
          <span className="ml-6 h-7 flex items-center">
            {/*Expand/collapse icon, toggle classes based on question open state.*/}

            {/*Open: "-rotate-180", Closed: "rotate-0"*/}

            <svg
              className={`${
                isOpen ? "-rotate-180" : "rotate-0"
              }  h-6 w-6 transform`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd
        className={`${
          isOpen ? "h-auto" : "h-0 overflow-hidden"
        } mt-2 md:pr-12 text-base leading-6 text-gray-500`}
      >
        {content}
      </dd>
    </div>
  );
};

const PhotoTitle = () => (
  <>
    <span role={"img"} aria-label={"camera-emoji"}>
      📷{" "}
    </span>
    Photo Submission
  </>
);

const PhotoContent = () => (
  <div className={"md:ml-6 space-y-2"}>
    <p>
      To submit a photo please fill out the form below. Alternatively, you may{" "}
      <UniversalLink
        url={"mailto:photo@iheartbd.com"}
        className={"border-b border-primary"}
      >
        email us
      </UniversalLink>{" "}
      your story.
    </p>
    <WufooForm formHash={"p19d9zxg1aiy3q2"} userName={"interquest"} />
  </div>
);

const StoryTitle = () => (
  <>
    <span role={"img"} aria-label={"writing-emoji"}>
      ✍️{" "}
    </span>
    Story Submission
  </>
);

const StoryContent = () => (
  <div className={"md:ml-6 space-y-2"}>
    <p>
      To submit a story please fill out the form below. Alternatively, you may{" "}
      <UniversalLink
        url={"mailto:story@iheartbd.com"}
        className={"border-b border-primary"}
      >
        email us
      </UniversalLink>{" "}
      your story.
    </p>
    <WufooForm formHash={"p8bv7xw05uwnf8"} userName={"interquest"} />
  </div>
);

const SponsorshipTitle = () => (
  <>
    <span role={"img"} aria-label={"sunglasses-emoji"}>
      😎{" "}
    </span>{" "}
    Sponsorship
  </>
);

const SponsorshipContent = () => (
  <div className={"md:ml-6 space-y-2"}>
    <p>
      Interested in becoming a sponsor? Please fill out and submit the form
      below and we will reach back out to you.
    </p>
    <WufooForm formHash={"p124xicm0i6e5is"} userName={"interquest"} />
  </div>
);

const GeneralInquiriesTitle = () => (
  <>
    <span role={"img"} aria-label={"person-raising-hand-emoji"}>
      🙋{" "}
    </span>
    General Inquiries
  </>
);

const GeneralInquiriesContent = () => (
  <div className={"md:ml-6 space-y-2"}>
    <p>For general inquiries, please fill out the form below:</p>
    <WufooForm formHash={"pbgky810uc86g1"} userName={"interquest"} />
  </div>
);

export default ContactMethodList;
