import React from "react";
// import Contact from "../components/sections/Contact";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import ContactMethodList from "../components/sections/ContactMethodList";

const ContactPage = () => {
  return (
    <Layout>
      <SEO title={"Contact"} />
      <section className={"container mx-auto section px-8"}>
        <h1 className="h1 text-center">
          Contact Methods
          <span role={"img"} aria-label={"rock-on-emoji"}>
            {" "}
            🤙
          </span>
        </h1>

        <ContactMethodList />
      </section>
      <span className={"mt-8 text-xs text-center block mx-auto"}>
        Disclaimer: any submissions are allowed to be re-used
      </span>
      <div className={"pb-8"} />
    </Layout>
  );
};

export default ContactPage;
